.specs-heading{
    text-align: center;
    color: #404040;
    font-size: 2.5em;
    font-weight: 300;
}

table{
    margin: auto;
    font-size: 14px;
}

td {
    width: 350px;
    padding: 10px;
    font-size: 16px;
    color: #404040;

}
b{
    font-size: 18px;  
}

@media only screen and (max-width: 720px) {
    b{
        font-size: 16px;  
    }
    td {
        width: 350px;
        padding: 10px;
        font-size: 14px;
        color: #404040;
    
    }
    .spec-name{
        width: 40%;
    }
}