.left-block,
.right-block {
  width: 70%;
  display: flex;
  margin-top: 50px;
}

.left-block {
  justify-content: flex-end;
  text-align: right;
}

.right-block {
  justify-content: flex-start;
  margin: auto 0 10px;
  margin-left: 30%;
  text-align: left;
}

.mini-block-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 8px 6px 8px 0px rgba(0, 0, 0, 0.5);
  border: solid 3px white;
}

.four-block {
  background-repeat: no-repeat;
  background-size: 55vw;
  max-width: 1500px;
  margin: auto;
  background-position: center;
  height: 700px;
}

.mini-block-text {
  margin: auto 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  max-width: 350px;
}

@media only screen and (min-width: 1250px) {
  .four-block {
    background-size: 700px;
  }
}

@media only screen and (max-width: 1024px) {
  .right-block {
    width: auto;
    margin: 10px;
  }

  .mini-block-image {
    width: 100px;
    height: 100px;
    min-width: 100px;
    max-width: 100px;
    margin: auto 0;
  }

  .mini-block-text {
    margin: auto 20px;
    background-color: transparent;
    padding: 0;
    font-size: 14px;
  }
}
