.image-list-item{
    display: flex;
}
.image-list-img {
    width: 25%;
    height: intrinsic;
    margin: 20px;
}
.image-list-content {
    padding: 20px;
}
.image-list-content > p {
    font-weight: 300;
    margin-right: 60px;
}

@media only screen and (min-width: 1366px) {
    .image-list-content > p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 600px) {
    .image-list-item{
        display: block;
    }
    .image-list-img {
        width: 90%;
        height: intrinsic;
        margin: 10px 5%;
    }
    .image-list-content > p {
        font-weight: 300;
        margin-right: 0px;
    }
}
