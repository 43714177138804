.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.blocks-container {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  overflow: hidden;
}

.home-block {
  width: 300px;
  margin: 30px;
}

.tg-cover {
  width: 100%;
}

.tg-icon {
  color: black;
  font-size: 46px;
  font-weight: bold;
}

.up-button {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 35px;
  line-height: 55px;
  transition: background-color 200ms;
  display: none;
}

.up-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.calculator {
  position: sticky;
  top: 0;
  height: auto;
  text-align: center;
  display: none;
  /* REMOVE TOP LINE FOR SHOW UP */
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 10px -8px black;
}

.calculator-size {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.sizing {
  width: 300px;
}

.price-button {
  margin: 30px 0px 0px;
}

.product-select {
  margin-top: 35px;
  font-size: 16px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clients {
  font-size: 3em;
}

@media only screen and (max-width: 1024px) {
  .clients {
    font-size: 1.8em;
  }

  .home-block {
    width: 150px !important;
    margin: 10px !important;
  }

  .tg-icon {
    font-size: 26px;
  }

  select:focus,
  input:focus {
    font-size: 16px;
    background: #fff;
  }

  .price-button {
    width: 90%;
  }

  .calculator-size {
    display: block;
  }

  .sizing {
    width: auto;
  }

  .price-button {
    margin: 10px 0px 0px;
  }

  .product-select {
    margin-top: 10px;
    font-size: 16px;
  }
}
