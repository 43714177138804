.company-name{
    /* margin: 5px 10px; */
    display: inline;
    font-size: 1.2em;
    font-weight: 500;
}
.company-img {
    display: table-cell;
    vertical-align:bottom;
    text-align:center;
    width:100vw;
    max-width:100vw;
    padding:0px 50px;
    height:400px;
}
.letter-btn {
    height: 30px;
    width: 30px;
    border: 1px solid #000;
    border-radius: 5px;
}
@media only screen and (max-width: 1024px) {
    .company-name{
        font-size: 1em;
        /* margin: 2px 4px */
    }
    .company-img{
        padding: 0px 0px;

    }
    .letter-btn {
        height: 24px;
        width: 24px;
        border: 1px solid #000;
        border-radius: 5px;
        font-size: 12px;
        line-height: 10px;
        padding: 0px !important;
        text-align: center !important;
    }
}
@media only screen and (max-width: 600px) {
    .company-img{
        padding: 0px 0px;
        background-size: 125% !important;
        background-position: 50% -140px !important;
        height: 220px;
    }
}