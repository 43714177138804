.blocks-container{
    text-align: center;
}

.home-block{
    width: 300px;
    margin: 30px;
}

.dropdown-image{
    width: 250px;
    height: 250px;
    margin: 25px;
}
