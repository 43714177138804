.footer-cont{
    color: black;
    background-color: #dedede;
    padding: 40px 0px;
}

.footer-cont > a {
    color: black;
    display: block;
}
.footer-link{
    color: black !important;
    display: block;
    margin: 5px;
}

.footer-link:hover{
    color: rgb(131,	27,	41) !important;
}

.futerLink {display: inline-flex}
		.futerImg {height: 30px;
					width: 30px;
					margin: 4px 5px 0px 5px}
		.futerImg:hover {
					width: 34px;
					height:34px;
			margin: 0px 3px 0px 3px;
		}


.related {
    display: flex;
    justify-content: center;
}
img.related {
     height: 48px;
    margin: 50px 48px 10px;
}
.top-related > img {
    height: 58px;
    margin: 40px 48px 0px;
}

.top-related > p {
    margin: 0px;
    margin-left: 40px;
    padding: 0px;
    font-size: 12px;
}
@media only screen and (max-width: 1024px) {
    img.related {
        height: 28px;
       margin: 40px 18px 10px;
   }
   .top-related > img {
    height: 32px;
    margin: 40px 18px 0px;
}
.top-related > p {
    margin-left: 20px;
    font-size: 12px;
}

}