.main-h,
.main-sh {
    text-align: center;
}

.main-h {
    font-size: 2.5em;
    margin-top: 60px;
    font-weight: 400;
}

.main-sh {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 50px;
}

.main-root,
.main-img-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
}

.main-root {
    margin-bottom: 60px;
}

.gridList {
    width: 120px;
    height: 250px;
}

.main-image {
    height: 400px;
    width: 600px;
    margin: 0 20px;
    background: center / contain no-repeat;
}

.text-cont {
    width: 320px;
    margin-left: 20px;
}

.main-contact {
    background-color: #2c7140;
    border-color: #2c7140;
    margin: 20px 0;
}

input {
    background-color: #fafafa;
    border: 1px solid #a3a3a3;
}

.arrow-btn {
    border: none !important;
    background-color: transparent;
    transition: 200ms;
    color: white;
    font-size: 4em;
    width: 50px;
    outline: none;
}

.arrow-btn:hover {
    background-color: rgba(0, 0, 0, 0.4);
    transition: 200ms;
    border: none !important;
    outline: none;
}

.arrow-btn::selection {
    background: none !important;
    border: none !important;
    outline: none;
}

.grayscale {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%);
}

@media only screen and (max-width: 1366px) {
    .text-cont {
        width: 280px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .main-h {
        font-size: 1.8em;
        margin-top: 30px;
    }

    .arrow-btn {
        font-size: 3em;
        width: 40px;
    }

    .main-sh {
        font-size: 16px;
        font-weight: 200;
        margin: 10px 10px;
    }

    .main-image {
        width: 90%;
        height: 60vw;
        margin: 0;
        background: center / contain no-repeat;
    }

    .text-cont {
        width: 90%;
        margin: 30px auto;
    }

    .main-img-cont {
        justify-content: center;
        width: 100%;
    }
}
