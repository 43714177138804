.top-img{
    width: 150px;
    height: auto;
}
.top-container{
    display: flex;
    justify-content: space-around;
    margin: 40px 0px;
}
.top-mini{
    max-width: 250px;
    text-align: center;
}

.top-mini > h3 {
    font-weight: 600;
    margin-top: 10px;
}

.top-mini > p {
    font-weight: 300;
    font-size: 12px;
}

.dc{
    font-weight: 700;
    text-align: center;
    font-size: 2.2em;
    margin-top: 40px;
}

@media only screen and (min-width: 1366px) {
    .top-mini > p {
        font-size: 14px;
    }
} 

@media only screen and (max-width: 1024px) {
    .top-img{
        width: 150px;
        height: auto;
    }
    .top-container{
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: space-around;
        margin: 40px 0px;
    }
    .top-mini{
        max-width: 150px;
        margin: 20px 0px;
    }
    .top-img{
        width: 120px;
        height: auto;
    }
} 