.pp-container{
    display: flex;
    margin: 20px 0px
}

.pp-content{
    width: 60%;
    padding: 60px;
}
.pp-img-container{
    width: 40%;
    margin: auto 60px auto 0px;

}
.pp-img-container-r{
    width: 40%;
    margin: auto 0px auto 60px;
}
.pp-img{
    width: 100%;
}
.pp-h{
    font-weight: 700;
    font-size: 2.4em;
}
.pp-p{
    color: #707070;
    font-weight: 300;
    font-size: 1.2em;
}

@media only screen and (max-width: 1024px) {
    .pp-container{
        display: block;
        margin: 20px 0px
    }

.pp-content{
    width: 100%;
    padding: 20px;
}
.pp-img-container{
    width: 100%;
    margin: 20px 0px;

}
.pp-img-container-r{
    width: 100%;
    margin: 20px 0px;

}
}