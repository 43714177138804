.imageContact-img{
    width: 70%;
    margin-bottom: 40px;
}

.imageContact-h{
    font-size: 2.5em;
    font-weight: 500;
    font-style: italic;
}

.imageContact-p{
    width: 60%;
    margin: auto;
    text-align: left !important;
}

@media only screen and (max-width: 720px) {
    .imageContact-p{
        width: 90%;
    }
    .imageContact-h{
        font-size: 2em;
        font-weight: 500;
        font-style: italic;
    }
    .imageContact-img{
        width: 90%;
        margin-bottom: 40px;
    }
}