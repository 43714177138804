.text-white-right{
background-color: rgba(255,255,255,0.8);
display: flex;
justify-content: center;
flex-direction: column;
box-sizing: content-box;
width: 25%;
margin-left: 65%;
padding: 10px
}
.text-white-left{
    background-color: rgba(255,255,255,0.8);
display: flex;
justify-content: center;
flex-direction: column;
box-sizing: content-box;
width: 25%;
margin-left: 10%;
padding: 10px
}

@media only screen and (max-width: 1366px) {
    .text-white-right{
        width: 40%;
        margin-left: 50%;
        }
    .text-white-left{
        width: 40%;
        margin-right: 50%;
    }
}
@media only screen and (max-width: 1024px) {
    .text-white-right{
        width: 80%;
        margin: auto;
        }
    .text-white-left{
        width: 80%;
        margin: auto;
    }
}
