.circles-container{
    display: flex;
    justify-content: space-around;
}

.circle-list > img{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    display: block;
    margin-bottom: 15px;
    border: solid 2px #bababa
}
.circle-list {
    margin: 40px 0px;
    text-align: center;
    max-width: 250px;
    
}

.circle-list > b {
    font-size: 14px;
}
.circle-list > li {
    font-size: 12px;
    margin-left: 0px;
}

.bold-normal {
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.bold-normal > b{
    font-size: 12px;
    margin-right: 5px;
}

@media only screen and (max-width: 1024px) {
    .circle-list > img{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: block;
        margin-bottom: 15px;
        border: solid 2px #bababa
    }
    .circles-container{
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: space-around;
    }
    .circle-list {
        margin: 20px 0px;
        text-align: center;
        max-width: 160px;
        
    }
} 