.imageText-container {
  display: inline-flex;
  margin: 20px 0;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.text-container,
.text-container-right {
  width: 40%;
  display: flex;
  text-align: left;
  margin: 0 5%;

  @media only screen and (max-width: 1024px) {
    width: auto;
    margin: 20px 5%;
  }
}

.text,
.text > p {
  margin: auto;
  font-size: 16px;
  font-weight: 300;

  @media only screen and (min-width: 1024px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 1366px) {
    font-size: 18px;
  }
}

.image-container {
  width: 50%;
  display: inline-block;

  @media only screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 5%;
  }
}

.image {
  width: 100%;
  /* border-radius: 30px; */
}
