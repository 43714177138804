#header-top{
    height: 80px;
    display: flex;
    justify-content: space-between;
}
header{
    position: sticky !important;
    top: 0;
    background-color: white;
    z-index: 999;
}

.move-icon{
    color: black ;
    font-size: 26px;
    font-weight: bold;
  }
.trista{
    width: 300px !important;

}
.logo{
    height: 60px;
    margin: 10px 30px;
}
.header-contact-btn{
    margin: 20px 30px;
    border: solid 2px rgb(131,	27,	41);
    color: rgb(131,	27,	41);
}

.head-support{
    margin: 20px 30px;
    font-size: 20px;
    color: black !important;
}
.support-container {
    display: flex;
    background-color: #fff;
    padding: 20px 10px;
    margin-top: 10px;
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.75);
}
.support-small{
    padding: 0px 10px;

}
.support-small > b {
    font-size: 12px;
}
.support-link {
    display: block;
    margin: 5px;
}
iframe{
    overflow-y: hidden;
}

#header-bar{
    height: 44px;
    background-color: #dedede;
    line-height: 44px;
}

#header-menu{
    margin: 0px auto;
    text-align: center;
}

.ant-dropdown-link{
    margin: 0px 12px;
    color: #000000;
    font-size: 14px;
}
.ant-dropdown-link:hover{
    /* font-weight: bold; */
    color: rgb(131,	27,	41);
    line-height: 44px;
}

.dropdown{
    background-color: #fff;
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.75);
    padding: 20px;
    display: inline-flex;
}

.dropdown-image-container{
    width: 300px;
}
.dropdown-menu{
    min-width: 200px;
}

.dropdown-image{
    width: 250px;
    height: 250px;
    margin: 25px;
}

.header-news{
    width: 100%;
    height: 30px;
    background-color: rgb(131,	27,	41);
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}
.six-00 {
    width: 600px !important
}


@media only screen and (max-width: 1366px) {
    .ant-dropdown-link{
        margin: 0px 6px;
        color: #000000;
        font-size: 12px;
    }
} 

@media only screen and (max-width: 1024px) {
    .head-support{
        margin: 0px 0px;
        font-size: 16px;
        color: black !important;
    }
    #header-menu{
        margin: 0px 20px;
        text-align: left;
    }
    .logo{
        height: 40px;
        margin: 10px 20px;
    }
    #header-top{
        height: 60px;
    }
    .dropdown-image{
        width: 120px !important;
        height: 120px !important;
        margin: 10px !important;
    }
    .menu-item-sub{
        color: black !important;
        margin-left: -15px;

    }
    .header-news{
        width: 100%;
        height: 24px;
        background-color: rgb(131,	27,	41);
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
    }
} 


.viber-button {
    position: fixed;
    right: 13px;
    bottom: 90px;
    transform: translate(-50%, -50%);
    background: #fff; 
    background-image: url("https://seeklogo.com/images/V/viber-logo-02B562CBA7-seeklogo.com.png");
    background-size: 40px; 
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    width: 55px; 
    height: 55px; 
    color: #fff;
    text-align: center;
    line-height: 53px; 
    font-family: 'montserrat', Arial, Helvetica, sans-serif;
    font-size: 9px;
    z-index: 9999;
}
.viber-button a {
    color: #fff;
}
.viber-button:before,
.viber-button:after {
    content: " ";
    display: block;
    position: absolute;
    border: 50%;
    border: 1px solid #7B519D; 
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 50%;
    animation: animate 1.5s linear infinite;
    opacity: 0;
    backface-visibility: hidden; 
}
 
.viber-button:after{
    animation-delay: .5s;
}
 
@keyframes animate
{
    0%
    {
        transform: scale(0.5);
        opacity: 0;
    }
    50%
    {
        opacity: 1;
    }
    100%
    {
        transform: scale(1.2);
        opacity: 0;
    }
}
@media (max-width : 800px) {  
.viber-button {   
    bottom: 5px; 
    left: 70px; 
  }
}
